// Fonts
@import url('https://fonts.googleapis.com/css?family=Source Sans Pro');

// Variables
@import '_variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

// sweet alert
@import '~sweetalert2/src/sweetalert2';
// import 'sweetalert2/src/sweetalert2.scss'

@import "vue-multiselect";

@import "~vue2-timepicker/dist/VueTimepicker.css";

@import "vue-good-table/src/styles/style.scss";

table.vgt-table {
    font-size: inherit;
}

.footer__row-count__label
.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {
    color: $blue;
}

.indigo {
    color: $indigo;
}

.purple {
    color: $purple;
}

.pink {
    color: $pink;
}

.red {
    color: $red;
}

.orange {
    color: $orange;
}

.yellow {
    color: $yellow;
}

.green {
    color: $green;
}

.teal {
    color: $teal;
}

.cyan {
    color: $cyan;
}

.nav-pills .nav-link.active.people {
    background-color: $yellow !important;
    color: $white;
}

.nav-pills .nav-link.people {
    background-color: rgba(220, 198, 53, 0.12) !important;
    color: $yellow;
}

.nav-pills .nav-link.active.company {
    background-color: $green !important;
    color: $white;
}

.nav-pills .nav-link.company {
    background-color: rgba(40, 167, 69, 0.08) !important;
    color: $green;
}

.btn-sm.people {
    background-color: $yellow !important;
    color: $white !important;
}

.btn-sm.company {
    background-color: $green !important;
    color: $white !important;
}

.btn-warning {
    color: $white;
}

.btn-warning:hover {
    color: $white;
}
